import {Injectable} from '@angular/core';
import {USER_NAME, MSAL_ACCOUNT_KEY} from './auth-vars-constants';
import {interval, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor() {
	}

	/**
	 * Retrieve user's CDSID
	 */
	static seconds = interval(2000);
	static subscription;
	static loginID = new Subject<string>();

	static retrieveCDSID(): string {
		const msal_key = JSON.parse(sessionStorage.getItem(MSAL_ACCOUNT_KEY))[0];
		const userName = JSON.parse(sessionStorage.getItem(msal_key))[USER_NAME];
		if (userName) {
			const cdsid = userName.split('@')[0];
			AuthService.loginID.next(cdsid);
			return cdsid;
		}
		return '';
	}
}
