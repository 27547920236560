import {Component, OnDestroy, OnInit, VERSION} from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '../environments/environment';
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {InteractionStatus} from "@azure/msal-browser";
import {filter, Subject, takeUntil} from "rxjs";

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;

	/** Subject used to signal when component is in ngOnDestroy */
	private readonly _destroying$ = new Subject<void>();
	constructor(
		private router: Router,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService
	) {}
	ngOnInit() {
		const agent = window.navigator.userAgent.toLowerCase();
		if (agent.indexOf('trident') > -1) { this.router.navigate(['browser-not-supported']); }
		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.checkAndSetActiveAccount();
			});
	}

	checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
		 */
		const activeAccount = this.authService.instance.getActiveAccount();

		if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
			const accounts = this.authService.instance.getAllAccounts();
			this.authService.instance.setActiveAccount(accounts[0]);
		}
	}

	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
}
