import {Component, OnInit, Injectable, DebugElement, NgModule} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfirmationService} from 'primeng/api';
import {environment} from '../../environments/environment';
import {AuthService} from '../auth-service/auth.service';
import {FormsModule, NgForm} from '@angular/forms';
import {OobModel} from '../services/ios-one-off-builds/oob';
import {timeout} from 'rxjs/operators';

@Component({
	templateUrl: './ios-one-off-builds.component.html',
	styleUrls: ['../app.component.scss',
		'./ios-one-off-builds.component.scss']
})

export class IosOneOffBuildsComponent implements OnInit {

	branch_name: Object[];
	build_scheme: Object[];
	distribution_configuration: Object[];
	distribution_group: Object[];
	build_type: Object[];
	selected_branch: string;
	selected_scheme: string;
	selected_group: string;
	selected_build_type: string;
	message_text: string;
	showSuccess: boolean = false;
	cciWorkflowLink: string;
	loginID: string;
	status: string;

	constructor(private http: HttpClient, private router: Router, private confirmationService: ConfirmationService, private authService: AuthService) {

		this.build_scheme = [
			{name: "FordPass"},
			{name: "LincolnWay"}
		];

		this.build_type = [
			{name: "Alpha", alpha_beta: "alpha"},
			{name: "Beta", alpha_beta: "beta"}
		];
	}

//Creates oobModel to enable Angular 2-way data binding
	oobModel: OobModel = {
		branch: '',
		scheme: '',
		distGroup: '',
		releaseType: ''
	};

	// This function cycles through the selected_scheme array and calls createOneOffJob to
	// Start a one off build job on CircleCI for each scheme selected
	triggerBuildPipelines() {
		this.createOneOffJob(this.oobModel.branch["name"], this.oobModel.scheme["name"], this.oobModel.distGroup["name"], this.oobModel.releaseType["alpha_beta"], this.loginID);
	}

	// Trigger an individual one off build on CirlceCI
	createOneOffJob(branch, scheme, group, build_type, cdsid) {
		this.status = "loading";
		let my_url = environment.backend_url + "/configure_build/trigger_build/";
		this.http.post(my_url,
			{
				"branch": branch.toString(),
				"scheme": scheme.toString(),
				"dist_group": group.toString(),
				"release_type": build_type.toString(),
				"cdsid": this.loginID.toString()
			})
			.pipe(timeout(5000))
			.subscribe(
				() => {
					this.status = "success_response";
					this.showSuccess = true;
					this.cciWorkflowLink = "https://app.circleci.com/pipelines/github/FPLW/mobileapp-ios?branch=" + branch;
				},
				error => {
					this.status = "error_response";
					console.log("POST call in error", error);
				});
	}

	generateDistributionList(selected_groups) {
		let group_list = "";
		for (let index = 0; index < selected_groups.length; index++) {
			group_list = group_list + selected_groups[index]["name"];
			if (index < (selected_groups.length - 1)) {
				group_list = group_list + "-";
			}
		}
		return group_list;
	}

	confirm() {
		this.message_text = "Do you wish to create one-off builds based on your chosen configurations?";
		//The if statment only allows the confirmation message to display if all oobModel variables are not null.
		if (this.oobModel.branch && this.oobModel.distGroup && this.oobModel.releaseType && this.oobModel.scheme) {
			this.confirmationService.confirm({
				message: this.message_text,
				accept: () => {
					this.triggerBuildPipelines();
				}
			});
		}
	}

	ngOnInit() {
		this.status = "init";
		this.loginID = AuthService.retrieveCDSID();

		let github_branches_url = environment.backend_url + "/configure_build/list_github_branches";
		this.http.get(github_branches_url)
			.subscribe((github_branch_data: object[]) => {
					this.branch_name = github_branch_data;
				},
				error => {
					console.log(error);
				}
			);

		let appcenter_groups_url = environment.backend_url + "/configure_build/list_appcenter_groups";
		this.http.get(appcenter_groups_url)
			.subscribe((appcenter_group_data: object[]) => {
					this.distribution_group = appcenter_group_data;
				},
				error => {
					console.log(error);
				}
			);
	}

}
