import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import {DpTrendComponent} from './dp-trend/dp-trend.component';
import {DpSummaryComponent} from './dp-summary/dp-summary.component';
import { AuthGuardIos } from './auth-guard/auth.guard.anchor.ios';
import { IosOneOffBuildsComponent } from './ios-one-off-builds/ios-one-off-builds.component';
import {APITesting} from './api-testing/api-testing.component';
import {AuthGuardAPITesting} from './auth-guard/auth.guard.anchor.apitesting';
import { AlphaBetaFailureTrendComponent } from './alpha-beta-failure-trend/alpha-beta-failure-trend.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {MsalGuard} from "@azure/msal-angular";

const routes: Routes = [
	{path: '', component: HomeComponent, canActivate: [MsalGuard]},
	{path: 'browser-not-supported', component: BrowserNotSupportedComponent},
	{path: 'dp-trend', 	component: DpTrendComponent, canActivate: [MsalGuard]},
	{path: 'alpha-beta-failure-trend', component: AlphaBetaFailureTrendComponent, canActivate: [MsalGuard]},
	{path: 'dp-summary', component: DpSummaryComponent, canActivate: [MsalGuard]},
	{path: 'ios-one-off-service', component: IosOneOffBuildsComponent, canActivate: [AuthGuardIos]},
	{path: 'api-testing', component: APITesting, canActivate: [AuthGuardAPITesting]},
	{path: 'not-found', component: NotFoundComponent},
	{ path: '**', redirectTo: 'not-found' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		onSameUrlNavigation: 'reload',
		scrollPositionRestoration: 'top', useHash: false
})],
	exports: [RouterModule],
	providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class AppRoutingModule {}
