import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ToastModule} from 'primeng/toast';
import {SidebarModule} from 'primeng/sidebar';
import {CardModule} from 'primeng/card';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService, } from 'primeng/api';
import {AccordionModule} from 'primeng/accordion';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './home';
import { HeaderFooterComponent } from './header-footer/header-footer.component';
import {TooltipModule} from 'primeng/tooltip';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { DpTrendComponent } from './dp-trend/dp-trend.component';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';
import { DpSummaryComponent } from './dp-summary/dp-summary.component';
import { IosOneOffBuildsComponent } from './ios-one-off-builds/ios-one-off-builds.component';
import {CalendarModule} from 'primeng/calendar';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {APITesting} from './api-testing/api-testing.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {SearchFilter} from './search-filter'
import { RouterLinkStubDirective } from '../testing/router-stubs';
import { RouterOutletStubDirective } from '../testing/router-stubs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AlphaBetaFailureTrendComponent } from './alpha-beta-failure-trend/alpha-beta-failure-trend.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {environment} from '../environments/environment';
import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication, LogLevel,
	PublicClientApplication
} from "@azure/msal-browser";
import {
	MSAL_GUARD_CONFIG, MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService, MsalGuard,
	MsalGuardConfiguration, MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalModule, MsalService
} from "@azure/msal-angular";

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.msalConfig.clientId,
			authority: environment.msalConfig.authority,
			redirectUri: environment.msalConfig.redirectUri
		},
		cache: {
			cacheLocation: BrowserCacheLocation.SessionStorage
		},
		system: {
			allowNativeBroker: false, // Disables WAM Broker
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false
			}
		}
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(environment.apiConfig.DevOpsPulse.uri, environment.apiConfig.DevOpsPulse.scopes);
	// add additional scopes to protectedResourceMap here

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: [...environment.apiConfig.DevOpsPulse.scopes]
		}
	};
}

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		HeaderFooterComponent,
		BrowserNotSupportedComponent,
		DpTrendComponent,
		DpSummaryComponent,
		IosOneOffBuildsComponent,
		APITesting,
		SearchFilter,
		RouterLinkStubDirective,
		RouterOutletStubDirective,
		AlphaBetaFailureTrendComponent,
		NotFoundComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		FormsModule,
		HttpClientModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		SidebarModule,
		CardModule,
		MultiSelectModule,
		DropdownModule,
		ConfirmDialogModule,
		AccordionModule,
		MessagesModule,
		MessageModule,
		TooltipModule,
		TableModule,
		DialogModule,
		ListboxModule,
		CalendarModule,
		ProgressBarModule,
		ProgressSpinnerModule,
		DynamicDialogModule,
		MatListModule,
		ToastModule,
		ScrollingModule,
		MsalModule
	],
	providers: [MessageService, ConfirmationService, DialogService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
